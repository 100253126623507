import React, { lazy, Suspense, memo, useEffect } from "react";
import { useSelector } from "react-redux";
import Loading from "components/shared-components/Loading";
import Views from "views";
import store from "../store";
// import { onAuthStateChanged } from "firebase/auth";
// import { auth } from "auth/FirebaseAuth";
// import { signInSuccess } from "store/slices/authSlice";
//  import store from "../store";

const AppLayout = lazy(() => import("./AppLayout"));
const AuthLayout = lazy(() => import("./AuthLayout"));

const reduxState = store.getState();
const isMentee = reduxState?.auth?.user?.role === "mentee";

const Layouts = () => {
  const token = useSelector((state) => state.auth.token);
  const blankLayout = useSelector((state) => state.theme.blankLayout);
  const isRegistered = useSelector((state) => state.auth.isRegistered)

  const Layout = isRegistered && token && !blankLayout ? AppLayout : AuthLayout;

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Layout>
        <Views />
      </Layout>
    </Suspense>
  );
};

export default memo(Layouts);
