import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { 
	AUTH_PREFIX_PATH, 
	UNAUTHENTICATED_ENTRY, 
} from 'configs/AppConfig'
import { IS_REGISTERED } from 'constants/AuthConstant';

const ProtectedRoute = () => {
	
	const { token } = useSelector(state => state.auth)
	const isRegistered = useSelector(state => state.auth.isRegistered)
	const location = useLocation()

	if ((!token && (isRegistered === null)) || (token && (isRegistered === null)) || (!token && (isRegistered !== null))) {
		localStorage.removeItem(IS_REGISTERED)
		return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}`} replace />;
	}

	return <Outlet />
}

export default ProtectedRoute