import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";
import store from "../store";

const reduxState = store.getState();
const isMentee = reduxState?.auth?.user?.role === "mentee";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "login-verify-test",
    path: `${AUTH_PREFIX_PATH}/verify`,
    component: React.lazy(() =>
      import("views/app-views/components/loginverify")
    ),
  },
  {
    key: "login-register-test",
    path: `${AUTH_PREFIX_PATH}/register/test`,
    component: React.lazy(() =>
      import("views/app-views/components/testregister")
    ),
  },
  {
    key: "login-userinfo-test",
    path: `${AUTH_PREFIX_PATH}/userinfo/test`,
    component: React.lazy(() => import("views/app-views/components/userinfo")),
  },
  {
    key: "login-schedule-test",
    path: `${AUTH_PREFIX_PATH}/schedule/test`,
    component: React.lazy(() =>
      import("views/app-views/components/mentorschedule")
    ),
  },
  {
    key: "mentor-form-test",
    path: `${AUTH_PREFIX_PATH}/mentorform/test`,
    component: React.lazy(() =>
      import("../views/app-views/components/mentorform.js")
    ),
  },
  {
    key: "mentee-form-test",
    path: `${AUTH_PREFIX_PATH}/menteeform/test`,
    component: React.lazy(() =>
      import("../views/app-views/components/menteeform.js")
    ),
  },
  {
    key: "mentor-public-profile-page",
    path: `${AUTH_PREFIX_PATH}/mentorprofile/:name`,
    component: React.lazy(() =>
      import("../views/app-views/components/landingpage/newmentorprofile")
    ),
  },
  {
    key: "mentor-public-profile-page-new",
    path: `${AUTH_PREFIX_PATH}/profilementor/:name`,
    component: React.lazy(() =>
      import("../views/app-views/components/landingpage/newprofilementor")
    ),
  },
  {
    key: "landing-page",
    path: `${AUTH_PREFIX_PATH}/`,
    component: React.lazy(() =>
      import("../views/app-views/components/landingpage/landingpage.js")
    ),
  },
  {
    key: "mentor-list-page",
    path: `${AUTH_PREFIX_PATH}/mentorlist`,
    component: React.lazy(() =>
      import("../views/app-views/components/landingpage/newmentorlist.js")
    ),
  },
  {
    key: "mentor-list-page",
    path: `${AUTH_PREFIX_PATH}/mentorlist/:topicorexpertise`,
    component: React.lazy(() =>
      import("../views/app-views/components/landingpage/newfiltermentorlist.js")
    ),
  },
  {
    key: "mentor-list-page",
    path: `${AUTH_PREFIX_PATH}/testmentorlist`,
    component: React.lazy(() =>
      import("../views/app-views/components/landingpage/testmentorlist.js")
    ),
  },
  {
    key: "about-page",
    path: `${AUTH_PREFIX_PATH}/about`,
    component: React.lazy(() =>
      import("../views/app-views/components/landingpage/components/aboutpage")
    ),
  },
  {
    key: "contactus-page",
    path: `${AUTH_PREFIX_PATH}/contact`,
    component: React.lazy(() =>
      import("../views/app-views/components/landingpage/components/contactpage")
    ),
  },
  {
    key: "faq-page",
    path: `${AUTH_PREFIX_PATH}/faq`,
    component: React.lazy(() =>
      import("../views/app-views/components/landingpage/components/faqpage")
    ),
  },
  {
    key: "guidelines",
    path: `${AUTH_PREFIX_PATH}/guidelines`,
    component: React.lazy(() =>
      import("../views/app-views/components/landingpage/components/guidelines")
    ),
  },

  {
    key: "post-page",
    path: `${AUTH_PREFIX_PATH}/blog`,
    component: React.lazy(() =>
      import("../views/app-views/components/landingpage/components/postspage")
    ),
  },
  {
    key: "singlepost-page",
    path: `${AUTH_PREFIX_PATH}/blog/:slug`,
    component: React.lazy(() =>
      import(
        "../views/app-views/components/landingpage/components/singlepostpage"
      )
    ),
  },
  {
    key: "company-page",
    path: `${AUTH_PREFIX_PATH}/companies`,
    component: React.lazy(() =>
      import("../views/app-views/components/landingpage/components/companypage")
    ),
  },
  {
    key: "singlecompany-page",
    path: `${AUTH_PREFIX_PATH}/companies/:slug`,
    component: React.lazy(() =>
      import(
        "../views/app-views/components/landingpage/components/singlecompanypage"
      )
    ),
  },
  {
    key: "courses-page",
    path: `${AUTH_PREFIX_PATH}/courses`,
    component: React.lazy(() =>
      import("../views/app-views/dashboards/pages/courses/coursesPage.js")
    ),
  },
  {
    key: "single-course-page",
    path: `${AUTH_PREFIX_PATH}/course/:slug`,
    component: React.lazy(() =>
      import("../views/app-views/dashboards/pages/courses/singleCoursesPage.js")
    ),
  },
  {
    key: "mentees",
    path: `${AUTH_PREFIX_PATH}/who-are-our-mentees`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/pages/mentees/whoMentees.js")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: isMentee
      ? React.lazy(() =>
          import("views/app-views/dashboards/pages/home/homepage")
        )
      : React.lazy(() =>
          import("views/app-views/dashboards/pages/profile/profilepage")
        ),
  },
  {
    key: "dashboard.mentorprofile",
    path: `${APP_PREFIX_PATH}/dashboards/mentorprofile/:name`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/testpages/mentorprofile")
    ),
  },
  {
    key: "dashboard-home",
    path: `${APP_PREFIX_PATH}/dashboards/home`,
    component: React.lazy(() =>
      import("../views/app-views/dashboards/pages/home/homepage.js")
    ),
  },
  {
    key: "dashboard-history",
    path: `${APP_PREFIX_PATH}/dashboards/history`,
    component: React.lazy(() =>
      import("../views/app-views/dashboards/pages/history/historypage.js")
    ),
  },
  //  {
  //     key: 'dashboard-invite',
  //     path: `${APP_PREFIX_PATH}/dashboards/invite`,
  //     component: React.lazy(() => import('../views/app-views/dashboards/pages/home/invite.js')),
  // },
  {
    key: "dashboard-invite",
    path: `${APP_PREFIX_PATH}/dashboards/invite`,
    component: React.lazy(() =>
      import("../views/app-views/dashboards/pages/home/invite.js")
    ),
  },
  {
    key: "dashboard-courses",
    path: `${APP_PREFIX_PATH}/dashboards/courses`,
    component: React.lazy(() =>
      import("../views/app-views/dashboards/pages/courses/coursesPage.js")
    ),
  },
  {
    key: "dashboard-singlecourses",
    path: `${APP_PREFIX_PATH}/dashboards/course/:slug`,
    component: React.lazy(() =>
      import("../views/app-views/dashboards/pages/courses/singleCoursesPage.js")
    ),
  },
  {
    key: "dashboard-meetings",
    path: `${APP_PREFIX_PATH}/dashboards/meetings`,
    component: React.lazy(() =>
      import("../views/app-views/dashboards/pages/meetings/index1.js")
    ),
  },
  {
    key: "dashboard-feedback",
    path: `${APP_PREFIX_PATH}/dashboards/feedback`,
    component: React.lazy(() =>
      import("../views/app-views/dashboards/pages/feedback/index.js")
    ),
  },
  {
    key: "dashboard-profile",
    path: `${APP_PREFIX_PATH}/dashboards/profile`,
    component: React.lazy(() =>
      import("../views/app-views/dashboards/pages/profile/profilepage.js")
    ),
  },
  {
    key: "dashboard-menteeprofile",
    path: `${APP_PREFIX_PATH}/dashboards/menteeprofile`,
    component: React.lazy(() =>
      import("../views/app-views/dashboards/pages/profile/menteeprofilepage.js")
    ),
  },
  {
    key: "dashboard-settings",
    path: `${APP_PREFIX_PATH}/dashboards/settings`,
    component: React.lazy(() =>
      import("../views/app-views/dashboards/pages/settings/settingspage.js")
    ),
  },
  {
    key: "dashboard-editprofile",
    path: `${APP_PREFIX_PATH}/dashboards/editprofile`,
    component: React.lazy(() =>
      import("../views/app-views/dashboards/pages/profile/updateprofile.js")
    ),
  },
  {
    key: "dashboard-editprofile",
    path: `${APP_PREFIX_PATH}/dashboards/editprofile`,
    component: React.lazy(() =>
      import("../views/app-views/dashboards/pages/settings/settingspage.js")
    ),
  },
  {
    key: "dashboard-mentorlist",
    path: `${APP_PREFIX_PATH}/register/test/mentorlist`,
    component: React.lazy(() =>
      import("../views/app-views/components/mentorlist.js")
    ),
  },
  {
    key: "dashboard-bookingpage",
    path: `${APP_PREFIX_PATH}/register/test/bookingpage`,
    component: React.lazy(() =>
      import("../views/app-views/components/bookingpage.js")
    ),
  },
  {
    key: "dashboard-recommendedmentors",
    path: `${APP_PREFIX_PATH}/dashboards/recommendedmentors`,
    component: React.lazy(() =>
      import(
        "../views/app-views/dashboards/pages/profile/menteerecommendedmentors"
      )
    ),
  },
  {
    key: "dashboard-wishlist",
    path: `${APP_PREFIX_PATH}/dashboards/wishlist`,
    component: React.lazy(() =>
      import("../views/app-views/dashboards/pages/wishlist/wishlist.js")
    ),
  },
  {
    key: "post-page",
    path: `${APP_PREFIX_PATH}/blog`,
    component: React.lazy(() =>
      import("../views/app-views/components/landingpage/components/postspage")
    ),
  },
  {
    key: "singlepost-page",
    path: `${APP_PREFIX_PATH}/blog/:slug`,
    component: React.lazy(() =>
      import(
        "../views/app-views/components/landingpage/components/singlepostpage"
      )
    ),
  },
  {
    key: "mentees",
    path: `${APP_PREFIX_PATH}/who-are-our-mentees`,
    component: React.lazy(() =>
      import("views/app-views/dashboards/pages/mentees/whoMentees.js")
    ),
  },
  // {
  //     key: 'dashboard-messages',
  //     path: `${APP_PREFIX_PATH}/dashboards/messages`,
  //     component: React.lazy(() => import('../views/app-views/dashboards/pages/message/Message')),
  // },
];
