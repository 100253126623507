import {
  auth,
  signInWithEmailAndPassword,
  signOut,
  googleAuthProvider,
  facebookAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  db,
} from "auth/FirebaseAuth";
import axios from "axios";
import { CLOUD_FUNCTIONS_BASE_URL_USER } from "constants/ApiConstant";
import {
  collection,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore/lite";

const FirebaseService = {};

FirebaseService.signInEmailRequest = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password)
    .then((user) => user)
    .catch((err) => err);
};

FirebaseService.signOutRequest = async () =>
  await signOut(auth)
    .then((user) => user)
    .catch((err) => err);

FirebaseService.signInGoogleRequest = async () =>
  await signInWithPopup(auth, googleAuthProvider)
    .then((user) => user)
    .catch((err) => err);

FirebaseService.signInFacebookRequest = async () =>
  await signInWithPopup(auth, facebookAuthProvider)
    .then((user) => user)
    .catch((err) => err);

FirebaseService.signUpEmailRequest = async (email, password) =>
  await createUserWithEmailAndPassword(auth, email, password)
    .then((user) => user)
    .catch((err) => err);

FirebaseService.updateBooking = async (newBookingData) => {
  try {
    const idtoken = await auth?.currentUser.getIdToken(true);
    const response = await axios.post(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/updatebooking`,
      newBookingData,
      {
        headers: {
          authorization: `Bearer ${idtoken}`,
        },
      }
    );
    const result = response.data;
    const status = response.status;
    return status;
  } catch (error) {
    return error;
  }
};

FirebaseService.searchAllMentors = async () => {
  try {
    let mentorlist = [];
    const q = query(
      collection(db, "users"),
      where("category", "==", "mentor"),
      limit(100)
    );
    const querysnapshot = await getDocs(q);
    querysnapshot.forEach((doc) => {
      mentorlist.push(doc.data());
    });
    return mentorlist;
  } catch (error) {
    return error;
  }
};

FirebaseService.getPendingFeedback = async () => {
  try {
    const idtoken = await auth?.currentUser.getIdToken(true);
    const response = await axios.get(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/getpendingfeedback`,
      {
        headers: {
          authorization: `Bearer ${idtoken}`,
        },
      }
    );
    const result = response.data;
    return result;
  } catch (error) {
    return error;
  }
};

FirebaseService.getExpiredBookings = async () => {
  try {
    const idtoken = await auth?.currentUser.getIdToken(true);
    const response = await axios.get(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/getallexpiredbookings`,
      {
        headers: {
          authorization: `Bearer ${idtoken}`,
        },
      }
    );
    const result = response.data;
    return result;
  } catch (error) {
    return error;
  }
};

FirebaseService.getRescheduledBookings = async () => {
  try {
    const idtoken = await auth?.currentUser.getIdToken(true);
    const response = await axios.get(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/getallrescheduledbookings`,
      {
        headers: {
          authorization: `Bearer ${idtoken}`,
        },
      }
    );
    const result = response.data;
    return result;
  } catch (error) {
    return error;
  }
};

FirebaseService.updateUserData = async (data) => {
  try {
    const idtoken = await auth?.currentUser.getIdToken(true);
    const response = await axios.post(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/updateuserdata`,
      data,
      {
        headers: {
          authorization: `Bearer ${idtoken}`,
        },
      }
    );
    const responseData = response.data.message;
    const responseStatus = response.status;
    return { message: responseData, status: responseStatus };
  } catch (error) {
    return error;
  }
};
// getting recomended mentors
FirebaseService.getUserData = async () => {
  try {
    const idtoken = await auth?.currentUser.getIdToken(true);
    const response = await axios.get(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/getuserdata`,
      {
        headers: {
          authorization: `Bearer ${idtoken}`,
        },
      }
    );
    const result = response.data;
    return result;
  } catch (error) {
    return error;
  }
};

FirebaseService.getPendingBookings = async () => {
  try {
    const idtoken = await auth?.currentUser.getIdToken(true);
    const response = await axios.get(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/getallpendingbookings`,
      {
        headers: {
          authorization: `Bearer ${idtoken}`,
        },
      }
    );
    const result = response.data;
    return result;
  } catch (error) {
    return error;
  }
};

// returns - array of booked slots - [{date: '2022-10-13', slot: ['HH:mm-HH:mm','HH:mm-HH:mm','HH:mm-HH:mm']},{},{}]
// Calendar page - get booked slots of mentor
FirebaseService.getMentorBookedSlots = async (mentorEmail, timeZone) => {
  try {
    let data = {
      mentorEmail: mentorEmail,
      timeZone: timeZone,
    };
    const idtoken = await auth.currentUser.getIdToken(true);
    const response = await axios.post(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/bookedslots`,
      data,
      {
        headers: {
          authorization: `Bearer ${idtoken}`,
        },
      }
    );
    const result = response.data;
    return result;
  } catch (error) {
    console.log("error", error);
    return error;
  }
};

FirebaseService.getUpcomingBookings = async () => {
  try {
    //console.log("test")
    const idtoken = await auth.currentUser.getIdToken(true);
    const response = await axios.get(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/getallupcomingbookings`,

      {
        headers: {
          authorization: `Bearer ${idtoken}`,
        },
      }
    );
    console.log("response", response);
    const result = response ? response?.data : [];
    return result;
  } catch (error) {
    return error;
  }
};

FirebaseService.registerUser = async (data) => {
  try {
    let idtoken = await auth.currentUser.getIdToken(true);
    const response = await axios.post(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/register`,
      data,
      {
        headers: {
          authorization: `Bearer ${idtoken}`,
        },
      }
    );
    let returnobj = { data: response.data, status: "success" };
    return returnobj;
  } catch (error) {
    let returnobj = { data: error, status: "failed" };
    console.log(error.message);
    return returnobj;
  }
};

FirebaseService.getTopicList = async () => {
  try {
    const response = await axios.get(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/gettopics`
    );
    const result = response.data;
    return result;
  } catch (error) {
    console.log("error", error);
    return error;
  }
};

FirebaseService.unSearchMentors = async (searchquery) => {
  try {
    const response = await axios.post(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/unsearchmentors`,
      { searchquery: searchquery }
    );
    const result = response.data;
    return result;
  } catch (error) {
    console.log("error", error);
    return error;
  }
};

FirebaseService.unSearchAllMentors = async () => {
  try {
    const response = await axios.get(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/unsearchallmentors`
    );
    const result = response.data;
    return result;
  } catch (error) {
    console.log("error  ", error);
    return error;
  }
};

FirebaseService.searchMentors = async (searchquery) => {
  try {
    let data = {
      searchquery: searchquery,
    };
    // console.log("searchquery",searchquery)
    let idtoken = await auth?.currentUser?.getIdToken(true);
    if (!idtoken) {
      idtoken = localStorage.getItem("token");
    }
    const response = await axios.post(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/searchuser`,
      data,
      {
        headers: {
          authorization: `Bearer ${idtoken}`,
        },
      }
    );
    const result = response.data;
    return result;
  } catch (error) {
    console.log("error ", error);
    return error;
  }
};

// automatic recommended mentors by their expertise
FirebaseService.searchMentorsExpertise = async (searchquery) => {
  try {
    let data = {
      searchquery: searchquery,
    };
    const idtoken = await auth?.currentUser.getIdToken(true);
    const response = await axios.post(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/searchuserexpertise`,
      data,
      {
        headers: {
          authorization: `Bearer ${idtoken}`,
        },
      }
    );
    const result = response.data;
    return result;
  } catch (error) {
    console.log("error ", error);
    return error;
  }
};

FirebaseService.searchAllMentors = async () => {
  try {
    const idtoken = await auth?.currentUser.getIdToken(true);
    const response = await axios.get(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/searchmentors`,
      {
        headers: {
          authorization: `Bearer ${idtoken}`,
        },
      }
    );
    const result = response.data;
    return result;
  } catch (error) {
    console.log("error", error);
    return error;
  }
};

FirebaseService.bookMeeting = async (bookingdetails) => {
  try {
    const idtoken = await auth?.currentUser.getIdToken(true);
    const response = await axios.post(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/bookmeeting`,
      bookingdetails,
      {
        headers: {
          authorization: `Bearer ${idtoken}`,
        },
      }
    );
    const result = response.data;
    return result;
  } catch (error) {
    console.log("error", error);
    return error;
  }
};

FirebaseService.subscribeEmail = async (email) => {
  try {
    const response = await axios.post(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/subscribeemail`,
      { email }
    );
    const result = response.data;
    return result;
  } catch (error) {
    return error;
  }
};

FirebaseService.inviteemail = async (email, userName) => {
  try {
    const idtoken = await auth?.currentUser.getIdToken(true);
    const response = await axios.post(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/inviteemail`,
      { email },
      {
        headers: {
          authorization: `Bearer ${idtoken}`,
        },
      }
    );
    const result = response.data;
    return result;
  } catch (error) {
    return error;
  }
};

// single profile page without authentication
FirebaseService.getSingleMentorProfile = async (name) => {
  try {
    const formattedName = name.replace(/\s+/g, "-");
    const response = await axios.get(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/profilementor/${formattedName}`
    );
    const result = response.data;
    return result;
  } catch (error) {
    console.log("error", error);
    return error;
  }
};

// Add a mentor to the mentee's wishlist
FirebaseService.addToWishlist = async (mentorId) => {
  try {
    const idtoken = await auth?.currentUser.getIdToken(true);
    await axios.post(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/addtowishlist`,
      { mentorId },
      {
        headers: {
          authorization: `Bearer ${idtoken}`,
        },
      }
    );
    console.log("Mentor added to wishlist");
  } catch (error) {
    console.log("error", error);
  }
};

// Remove a mentor from the mentee's wishlist
FirebaseService.removeFromWishlist = async (mentorId) => {
  try {
    const idtoken = await auth?.currentUser.getIdToken(true);
    await axios.post(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/removefromwishlist`,
      { mentorId },
      {
        headers: {
          authorization: `Bearer ${idtoken}`,
        },
      }
    );
    console.log("Mentor removed from wishlist");
  } catch (error) {
    console.log("error", error);
  }
};

FirebaseService.getWishlist = async () => {
  try {
    const idtoken = await auth?.currentUser.getIdToken(true);
    const response = await axios.get(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/getwishlist`,
      {
        headers: {
          authorization: `Bearer ${idtoken}`,
        },
      }
    );
    const wishlistData = response.data;
    console.log("Wishlist Data:", wishlistData);
    return wishlistData;
  } catch (error) {
    console.log("error", error);
  }
};

FirebaseService.getOtherUserData = async (userId) => {
  try {
    let idtoken = await auth.currentUser.getIdToken(true);
    const response = await axios.get(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/user/${userId}`,
      {
        headers: {
          authorization: `Bearer ${idtoken}`,
        },
      }
    );
    let returnObj = response.data;
    return returnObj;
  } catch (error) {
    return error;
  }
};

FirebaseService.getOtherUserData = async (userId) => {
  try {
    let idtoken = await auth.currentUser.getIdToken(true);
    const response = await axios.get(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/users/${userId}`,
      {
        headers: {
          authorization: `Bearer ${idtoken}`,
        },
      }
    );
    let returnObj = response.data;
    return returnObj;
  } catch (error) {
    return error;
  }
};

// GET MENTORS
FirebaseService.getMentorDetails = async (mentorId) => {
  console.log("mentorId", mentorId);
  try {
    // let idtoken = await auth.currentUser.getIdToken(true);
    const response = await axios.get();
    let returnObj = response.data;
    return returnObj;
  } catch (error) {
    return error;
  }
};

FirebaseService.getMentorStats = async (mentorId, timeZone) => {
  try {
    const response = await axios.get(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/statistics?mentorId=${mentorId}&timeZone=${timeZone}`
    );
    let returnObj = response.data;
    return returnObj;
  } catch (error) {
    return error;
  }
};

FirebaseService.createCheckoutSession = async (data) => {
  try {
    const response = await axios.post(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/create-checkout-session`,
      data
    );
    let returnObj = response.data;

    return returnObj;
  } catch (error) {
    console.log(error);
    return error;
  }
};

FirebaseService.getAllCourses = async () => {
  try {
    const response = await axios.get(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/courses`
    );
    let returnObj = response.data;

    return returnObj;
  } catch (error) {
    console.log(error);
    return error;
  }
};

FirebaseService.getCourseBySlug = async (slug) => {
  try {
    const response = await axios.get(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/course-by-slug?slug=${slug}`
    );
    let returnObj = response.data;

    return returnObj;
  } catch (error) {
    console.log(error);
    return error;
  }
};

FirebaseService.getAllPosts = async () => {
  try {
    const response = await axios.get(`${CLOUD_FUNCTIONS_BASE_URL_USER}/posts`);
    let returnObj = response.data;

    return returnObj;
  } catch (error) {
    console.log(error);
    return error;
  }
};

FirebaseService.getPostBySlug = async (slug) => {
  try {
    const response = await axios.get(
      `${CLOUD_FUNCTIONS_BASE_URL_USER}/post-by-slug?slug=${slug}`
    );
    let returnObj = response.data;

    return returnObj;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export default FirebaseService;
